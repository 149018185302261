document.addEventListener('DOMContentLoaded', function() {

    const boxes = Array.from(document.querySelectorAll(".box"));
    boxes.forEach((box) => {
        box.addEventListener("click", boxHandler);
    });
    function boxHandler(e) {
        e.preventDefault();
        let currentBox = e.target.closest(".box");
        let currentContent = currentBox.querySelector(".box-content");
        currentBox.classList.toggle("active");
        if (currentBox.classList.contains("active")) {
            currentContent.style.maxHeight = currentContent.scrollHeight + "px";
        } else {
            currentContent.style.maxHeight = 0;
        }
    }

});